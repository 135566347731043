import $ from 'jquery';
window.$ = window.jQuery = $;

////additional scripts with jQuery
require ('../assets/js/functions.js');
require ('../assets/js/infinite-scroll.js');
require ('../assets/js/isotope.pkgd.min.js');
require ('../assets/js/perfect-scrollbar.jquery.min.js');
require ('../assets/js/slick.min.js');
require ('../assets/js/custom.js');
