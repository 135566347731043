//facebook sharing button
function share_fb(url) {
  window.open('https://www.facebook.com/sharer/sharer.php?u='+url,'facebook-share-dialog',"width=626, height=436")
}
$('.fb-share').click(function(e){
	e.preventDefault();
	const url = $(this).attr('href');
	share_fb(url);
})
//facebook sharing button
//pinterest sharing button
let script = document.createElement('script');
script.src = "//assets.pinterest.com/js/pinit.js";
document.body.append(script); // (*)
//pinterest sharing button